// connoisseur-site/src/contents/PressBuckle.js
import React from 'react';
import { Typography } from '@mui/material';

function PressBuckle() {
  return (
    <div style={{ maxWidth: '800px', margin: '0 auto' }}>
      {/* <h1>Connoisseur、BUCKLE COFFEEとの共同実証を発表</h1> */}
      <Typography variant="h4" align="left" style={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: 'bold' }}>
        Connoisseur、BUCKLE COFFEEとの共同実証を発表
      </Typography>
      <Typography align="right">
        2025年3月6日 8時00分
      </Typography>
      <Typography variant="body1" align="left" style={{ marginTop: '1rem', fontWeight: 'bold' }}>
        Connoisseur（拠点：東京都文京区、代表：岡田拓真、以下「当社」）は、BUCKLE COFFEEを展開する丸和工業株式会社（以下「BUCKLE COFFEE」）と共同で、AI技術を活用したコーヒー事業のサンプリング業務の高度化に向けた取り組みを開始いたしました。
      </Typography>

      <img src="/BUCKLECOFFEE-Connoisseur.png" alt="BUCKLECOFFEE-Connoisseur" className="img-fluid" style={{ marginTop: '2rem', width: 'auto', height: 'auto' }} />

      
      <Typography variant="h6" align="left" style={{ marginTop: '6rem', marginBottom: '0.5rem', fontWeight: 'bold' }}>
        ■取り組みの背景
      </Typography>
      <Typography align="left">
        近年、コーヒー市場の多様化に伴い、消費者ニーズがますます細分化される中、安定的かつ高品質な製品提供が不可欠となっています。しかし、コーヒー豆の品質評価やサンプリング業務は職人の経験や勘に大きく依存し、客観的かつ精緻な評価が難しい状況でした。当社は、AI技術を活用してこれまで評価が困難だった豆の微細な特性を客観的に捉え、安定的なサンプリングを実現しながら商品ポートフォリオの精緻化を実現したいと考えています。
      </Typography>
      
      <Typography variant="h6" align="left" style={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: 'bold' }}>
        ■取り組みの概要
      </Typography>
      <Typography align="left">
        本取り組みでは、当社の持つAI技術とBUCKLE COFFEEの焙煎技術および豆の評価ノウハウを融合し、コーヒー豆の特性を数値化・可視化し、比較評価できる技術開発を進めます。具体的には、センサー技術と機械学習アルゴリズムを組み合わせることで、従来は感覚で判断していた豆の品質特性を詳細かつリアルタイムに評価・分析し、過去のデータとの比較を通じて傾向や特性を明らかにします。これにより、人間による従来の評価手法よりも広範囲かつ詳細なサンプリングが可能となり、これまで経験と勘に頼っていた微細な品質差異を明確化し、商品ポートフォリオの精緻化を図ります。
      </Typography>
      
      <Typography variant="h6" align="left" style={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: 'bold' }}>
        ■サンプリング業務の高度化について
      </Typography>
      <Typography align="left">
        コーヒー事業においてサンプリング業務は中核的な役割を担っており、その高度化により競争力を強化できます。AI技術を活用して、従来の職人感覚に依存していた豆評価を客観的な数値化・可視化に刷新し、豆の微細な特性を正確に把握することで、製品品質の向上と商品ポートフォリオの差別化を実現します。
      </Typography>
      
      <Typography variant="h6" align="left" style={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: 'bold' }}>
        ■今後の展望
      </Typography>
      <Typography align="left">
        今後は共同で実証を重ね、システムの実用化を図ってまいります。将来的には、本技術を他の商品開発プロセスや品質管理体制に拡充し、コーヒー業界全体の品質基準向上に寄与します。また、消費者の多様な嗜好に応える新商品開発や、顧客体験のさらなる向上にも積極的に本技術を活用し取り組んでまいります。
      </Typography>
      <Typography align="left">
        本取り組みに関する詳細や進捗状況については、随時お知らせいたします。引き続き、当社およびBUCKLE COFFEEへのご支援とご愛顧を賜りますようお願い申し上げます。
      </Typography>
      
      <Typography variant="h6" align="left" style={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: 'bold' }}>
        丸和工業株式会社 会社概要
      </Typography>
      <Typography align="left">
        丸和工業株式会社<br />
        所在地：〒144-0046 東京都大田区東六郷2-4-14 MANU ZOSHIKI 1F<br />
        代表者：代表取締役 石山 俊太郎<br />
        設立：1963年10月21日<br />
        事業内容：コーヒーおよび関連商品の製造・販売、コーヒーストアの経営、コーヒー店舗・メニューコンサルティング業務<br />
        URL：<a href="https://www.bucklecoffee.jp/">https://www.bucklecoffee.jp/</a>
      </Typography>
      <img src="/BUCKLECOFFEE_shop.jpg" alt="BUCKLECOFFEE-shop" className="img-fluid" style={{ marginTop: '2rem', width: '50%', height: 'auto' }} />
      
      <Typography variant="h6" align="left" style={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: 'bold' }}>
        Connoisseurプロジェクト概要
      </Typography>
      <Typography align="left">
        Connoisseur（カノサー）<br />
        代表者：岡田 拓真（おかだ たくま）<br />
        2024年6月にIPAより未踏スーパークリエイターとして認定。エスプレッソ抽出制御システム「Connoisseur」を開発し、バリスタの技術をAIで再現する取り組みを行っている。<br />
        事業内容：コーヒーおよび関連商品の製造・販売<br />
        URL：<a href="https://connoisseur.ltd/">https://connoisseur.ltd/</a>
      </Typography>
      
      
      <Typography variant="h6" align="left" style={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: 'bold' }}>
        本件に関するお問い合わせ先
      </Typography>
      <Typography align="left">
        Connoisseur（カノサー）<br />
        URL：<a href="https://connoisseur.ltd/">https://connoisseur.ltd/</a>
      </Typography>
      <Typography align="left">
        本プレスリリースに関する詳細や取材のご依頼は、上記の連絡先までお問い合わせください。
      </Typography>

      <Typography variant="body1" align="left" style={{ marginTop: '3rem', fontWeight: 'bold' }}>
      Prtimesでの配信ページはこちら：<a href="https://prtimes.jp/main/html/rd/p/000000001.000158388.html">https://prtimes.jp/main/html/rd/p/000000001.000158388.html</a>
      </Typography>

    </div>
  );
}

export default PressBuckle;