import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Typography, Grid } from '@mui/material';
// import { Typography, Box, Button } from '@mui/material';

const ProductPage = () => {
  return (
    <div className="container mt-5 pt-5">
      <h1 className="product-title">Product</h1>
      {/* <p>表現力の獲得</p> */}
      <p>私たちのエスプレッソマシン制御装置は、温度制御技術を駆使することにより、緻密な味の表現を可能にしました。</p>
      {/* <p>私たちの緻密なエスプレッソマシン制御装置は、温度制御技術を駆使して、家庭でもプロフェッショナルな味を楽しめるよう設計されています。</p> */}
      <hr />
      
      <Box display="flex" flexDirection="row" mt={5}>
        <Box flex={1}>
          <img src="/machine.jpeg" alt="Espresso Machine" className="img-fluid" style={{ width: '66.66%' }} />
        </Box>
        <Box flex={1} display="flex" alignItems="center">
          <Typography>
            〜表現力の獲得〜<br />
            既存のエスプレッソマシンにこのソフトウェアを組み込むことにより、精密な温度での抽出が可能になります。
            これは既存のエスプレッソマシンと比較して表現力が数倍になったことを意味します。
            これにより、最高品質のエスプレッソ抽出が可能になります。
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={5} mt={5}>
        <Grid item xs={12} md={6}>
          <video src="/controller.mov" controls className="img-fluid"></video>
        </Grid>
        <Grid item xs={12} md={6} display="flex" alignItems="center">
          <Typography align="center">
            <strong>制御ソフトウェア</strong><br />
            私たちは温度を精密に制御するソフトウェアを開発し、数種類の温度での抽出を可能にしました。その結果、以下のようなフレーバーの生成に成功しました。また抽出後の温度差を可視化することでバリスタが正確に温度を把握できるようにしています。そして抽出量や抽出時間の設定を通して、抽出量を正確に管理できるようにしました。<br />
            <strong className="mt-3 d-block">生成に成功したフレーバー</strong><br />
            <div style={{ textAlign: 'center' }}>
              80度：完熟マンゴー<br />
              90度：酸味のあるマンゴー<br />
              98度：未熟さのあるマンゴー
            </div>
          </Typography>
        </Grid>
      </Grid>
      
      <div className="d-flex justify-content-end mt-3">
        <Button variant="outlined" color="secondary" component={Link} to="/flavor" style={{ borderRadius: '50px' }}>
          Flavor...
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 1 1-.708-.708L9.293 8H1.5a.5.5 0 0 1 0-1h7.793l-2.147-2.146a.5.5 0 1 1 .708-.708l3 3z"/>
          </svg>
        </Button>
      </div>

      <h2 className="mt-5 user-voice-title">User Voice</h2> {/* mt-5 で余白を追加 */}
      <div className="row mt-5">
        <div className="col-md-6 d-flex justify-content-center mb-4">
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/Ol6Wko-c7e8?start=824" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen>
          </iframe>
        </div>
        <div className="col-md-6">
          <div className="testimonial">
            <p><strong>芸術系大学 A教授</strong></p>
            <p>"完熟マンゴーを飲ませてもらって。でもその文字の情報だとイメージできないじゃないですか。コーヒーとマンゴー？みたいな。どうなるんだろうという気持ちのままで最初に一口飲んだら、めっちゃマンゴー。完熟マンゴー独特の甘味、香りが鼻に抜けるような感じで。"</p>
          </div>
          <div className="testimonial mt-5">
            <p><strong>コーヒーチェーン店員 Bさん</strong></p>
            <p>"マンゴーの味がしたんですよ。香りもほんのりフルーティーな感じがあって。本当にマンゴーなのかなというのがあったんですけど、口の中に入れた時にしっかりとした、ジュワッとした少しジューシーさのあるマンゴー感をすごい感じて、そこにエスプレッソならではの香ばしさが乗ってきて、マンゴーのいい甘みの部分がすごい感じられて。2種類飲みました。完熟マンゴーと若さのあるマンゴー。完熟マンゴーは、甘味を重点に置いた完熟マンゴーならではの、ジュワッとした広がりだったんですけど、若さのあるマンゴーはちょっとさっぱりしたシャキッとした甘味、いい意味でさっぱりしていてまとわりつかないような。完熟マンゴーは蜂蜜のような甘味。とろっとした口の中にずっといるような甘味。若い方のマンゴーは粉砂糖のようなさっと甘味が一瞬くるんですけど綺麗にくどくなく消えていく感じがあって、最初の香りに関しては大きな違いはなくて、フルーティーさという共通性を持っていて、口に広がった時に香りの広がり方、あと後味の残り方、同じマンゴーなのに違いが出てきて。"</p>
          </div>
        </div>
      </div>
      
      <div className="d-flex justify-content-end mt-3">
        <Button variant="outlined" color="secondary" component={Link} to="/flavor" style={{ borderRadius: '50px' }}>
          Flavor...
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L9.293 8H1.5a.5.5 0 0 1 0-1h7.793l-2.147-2.146a.5.5 0 1 1 .708-.708l3 3z"/>
          </svg>
        </Button>
      </div>
      <Box my={12} />
    </div>
  );
};

export default ProductPage;
