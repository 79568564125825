// cd ~/connoisseur-site && npm start
// bash '/home/t-lin-hp2/connoisseur-site/00_ubuntu-usage/deploy.sh'
import React, { useState } from 'react';
import {Route, Link, Routes, BrowserRouter as Router } from 'react-router-dom';
import NewsPage from './tab-News';
import NotificationDetail from './NotificationDetail';
import ResearchPage from './tab-Research';
import ProductPage from './tab-Product';
import AboutPage from './tab-About';
import FlavorPage from './tab-Flavor';
import { AppBar, Toolbar, IconButton, Box, Tabs, Tab, Container, Drawer, List, ListItem, ListItemText, useMediaQuery, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import './App.css';
import AppleCardsCarouselDemo from './AppleCardsCarouselDemo';
import LoginPage from './LoginPage';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';
import ReactDOM from 'react-dom';
import EventPage from './tab-Event';
import LinksPage from './contents/LinksPage';
import PressBuckle from './contents/PressBuckle';
import NewsList from './components/NewsList';
Amplify.configure(awsconfig);
const showLoginButton = true; // ログインボタンの表示を制御するフラグ


// {/* <Box sx={{ mt: 2, mr: 3, mb: 4, ml: 5 }}>上右下ひだり</Box> */}

ReactDOM.render(
  <Authenticator.Provider>
    <Router>
      <App />
    </Router>
  </Authenticator.Provider>,
  document.getElementById('root')
);


function App() {
  const [value, setValue] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  
  const navigate = useNavigate();
  const { user, signOut } = useAuthenticator();
  const handleLogout = async () => {
    await signOut();
    navigate('/');
  };
  
  const drawer = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        <ListItem button component={Link} to="/" onClick={() => setValue(0)}>
          <ListItemText primary="Overview" />
        </ListItem>
        <ListItem button component={Link} to="/news" onClick={() => setValue(1)}>
          <ListItemText primary="News" />
        </ListItem>
        <ListItem button component={Link} to="/crowdfunding" onClick={() => setValue(2)}>
          <ListItemText primary="Event" />
        </ListItem>
        <ListItem button component={Link} to="/flavor" onClick={() => setValue(3)}>
          <ListItemText primary="Flavor" />
        </ListItem>
        <ListItem button component={Link} to="/product" onClick={() => setValue(4)}>
          <ListItemText primary="Product" />
        </ListItem>
        <ListItem button component={Link} to="/research" onClick={() => setValue(5)}>
          <ListItemText primary="Research" />
        </ListItem>
        <ListItem button component={Link} to="/about" onClick={() => setValue(6)}>
          <ListItemText primary="About" />
        </ListItem>
        <ListItem button component={Link} to="/press-release" onClick={() => setValue(7)}>
          <ListItemText primary="Press Release" />
        </ListItem>
      </List>
    </Box>
  );

  return (
      <div className="App">
        <AppBar position="static" sx={{ backgroundColor: 'white', color: 'black' }}>
          <Toolbar>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton edge="start" color="inherit" aria-label="logo" sx={{ mr: 1, borderRadius: 0 }}>
                <img src="/connoisseur_logo_white.png" alt="Connoisseur Logo" style={{ width: '50px', height: '50px' }} />
              </IconButton>
              <img src="/connoisseur_logo_typo2.png" alt="Connoisseur Logo Typo" style={{ height: '35px' }} />
            </Box>

            {isMobile ? (
              // モバイルの場合、ハンバーガーメニューアイコンを表示
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ ml: 'auto' }}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              // デスクトップの場合、通常のタブを表示
              <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                <Tabs value={value} onChange={handleChange} aria-label="nav tabs" textColor="inherit" indicatorColor="primary">
                  <Tab label="Overview" component={Link} to="/" />
                  <Tab label="News" component={Link} to="/news" />
                  <Tab label="Event" component={Link} to="/crowdfunding" />
                  <Tab label="Flavor" component={Link} to="/flavor" />
                  <Tab label="Product" component={Link} to="/product" />
                  <Tab label="Research" component={Link} to="/research" />
                  <Tab label="About" component={Link} to="/about" />
                </Tabs>

              </Box>
            )}

            {/* Login/Logout ボタンをナビゲーションの右側に配置 */}
            <Box sx={{ ml: 2 }}>
              {showLoginButton && (
                user ? (
                  <Button onClick={handleLogout}>Logout</Button>
                ) : (
                  <Button component={Link} to="/login">Login</Button>
                )
              )}
            </Box>
                      
          </Toolbar>
        </AppBar>
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawer}
        </Drawer>
        <Container sx={{ mt: 5, pt: 5 }}>
          <Routes>
            <Route path="/" element={
              <>

                {/* 乳化量解析セクション */}
                <h2 className="text-center mt-5">Our Concept</h2>
                <section id="emulsion-analysis" className="text-center">
                  <p className="mt-3 text-wide">
                    私たちはエスプレッソのさらなる品質向上を目指して品質把握技術の開発に取り組んでいます。
                  </p>
                  <div className="gif-container large-gif">
                    <img src="/230728_generate_crop.gif" alt="New Research GIF" className="img-fluid" style={{ width: '300px', height: 'auto' }} />
                  </div>

                </section>
                
                {/* Recent Newsセクション */}
                <section id="top">
                  <h2 className="text-start mt-6 mb-4">Recent News</h2>
                  <NewsList maxItems={5} />
                  <div className="d-flex justify-content-end mt-3">
                    <Link to="/news" className="btn btn-outline-secondary rounded-pill">
                      All News...
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L9.293 8H1.5a.5.5 0 0 1 0-1h7.793l-2.147-2.146a.5.5 0 1 1 .708-.708l3 3z" />
                      </svg>
                    </Link>
                  </div>
                </section>

                {/* AppleCardsCarouselDemo */}
                <section id="carousel">
                  <AppleCardsCarouselDemo />
                </section>
                <Box my={12} />

                {/* プロジェクト紹介動画セクション */}
                <h2 className="text-center mt-8 mb-4">Published Video</h2>
                <section id="video">
                  <Box display="flex" justifyContent="center" mb={4}>
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/Ol6Wko-c7e8?start=824"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </Box>
                </section>
                
                {/* お問い合わせセクション */}
                <section id="contact">
                  <h2>Contact Us</h2>
                  <p>Contact us at: <a href="https://x.com/mtakuma32" target="_blank" rel="noopener noreferrer">https://x.com/mtakuma32</a></p>
                </section>   

              </>
            } />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/news/:id" element={<NotificationDetail />} />
            <Route path="/research" element={<ResearchPage />} />
            <Route path="/product" element={<ProductPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/flavor" element={<FlavorPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/crowdfunding" element={<EventPage />} />
            <Route path="/links" element={<LinksPage />} />
            <Route path="/press-release" element={<PressBuckle />} />
            </Routes>
        </Container>
        <Box my={12} />
        <footer className="bg-light text-center py-3">
          <p>
          Powered by <a href="https://www.sakura.ad.jp/corporate/">SAKURA internet</a>.<br/>
            © 2025 TAKUMA OKADA. All rights reserved.
          </p>
        </footer>
      </div>
  );
}

export default App;