import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import NewsList from './components/NewsList';
import AppleCardsCarouselDemo from './AppleCardsCarouselDemo';

const NewsPage = () => {

  return (
    <Container className="mt-5 pt-5">
      <Typography variant="h3" style={{ marginBottom: '0.5rem'}}>All News</Typography>
      <Box className="recent-news-list">
        <NewsList maxItems={Infinity} />
      </Box>
      <section id="carousel">
        <AppleCardsCarouselDemo />
      </section>
    </Container>
  );
};

export default NewsPage;
