// MUIの記法
import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box, Button } from '@mui/material';

const FlavorPage = () => {
  return (
    <div className="container mt-5 pt-5">
      <Typography variant="h3" align="center" style={{ marginBottom: '1rem'}}>
      Flavor
      </Typography>
      <Typography variant="body1" >
        香りを知る、五感最後のフロンティア。<br />
        私たちはエスプレッソの豊かな香りの識別を目的とした製品開発を通して、バリスタのさらなる表現能力の獲得を目指しています。
      </Typography>
      <hr />
      <Box my={12} />


      <Typography variant="h5" align="center" sx={{ mt: 5, mb: 1, fontWeight: 'bold' }}style={{ marginBottom: '1rem'}}>
        生成に成功したフレーバー
      </Typography>
      <Typography variant="body1" style={{ marginBottom: '2rem'}}>
        温度を精密に制御するソフトウェアを開発し、数種類の温度での抽出を可能に。
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center" style={{ margin: '0 auto', width: 'fit-content' }}>
        <img src="/Mango-1.jpeg" alt="Mango" className="img-fluid" style={{ width: '50%', marginRight: '3rem' }} />
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h6" style={{ marginBottom: '2rem' ,fontWeight: 'bold' }}>
            80度：完熟マンゴー<br />
            90度：酸味のあるマンゴー<br />
            98度：未熟さのあるマンゴー
          </Typography>
          <Typography variant="body1">
            〜表現力の獲得〜<br />
            抽出温度を可視化することでバリスタが正確に温度を把握できるようにしています。
            そして抽出量や抽出時間の設定を通して、抽出量を正確に管理できるようにしました。
            その結果、上記フレーバーの生成に成功しました。
          </Typography>
        </Box>
      </Box>

      {/* ボタン */}
      <div className="d-flex justify-content-end mt-3">
        <Button variant="outlined" color="secondary" component={Link} to="/product" style={{ borderRadius: '50px' }}>
          Our Product...
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L9.293 8H1.5a.5.5 0 0 1 0-1h7.793l-2.147-2.146a.5.5 0 1 1 .708-.708l3 3z"/>
          </svg>
        </Button>
      </div>
      <Box my={9} />

      <Typography variant="h3" align="center" style={{ marginTop: '2rem', marginBottom: '1rem'}}>User Voice</Typography>
      <div className="row mt-5">
        <div className="col-md-6 d-flex justify-content-center mb-4">
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/Ol6Wko-c7e8?start=824" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen>
          </iframe>
        </div>
        <div className="col-md-6">
          <div className="testimonial">
            <Typography variant="h6"><strong>芸術系大学 A教授</strong></Typography>
            <Typography variant="body2">
              "完熟マンゴーを飲ませてもらって。でもその文字の情報だとイメージできないじゃないですか。コーヒーとマンゴー？みたいな。どうなるんだろうという気持ちのままで最初に一口飲んだら、めっちゃマンゴー。完熟マンゴー独特の甘味、香りが鼻に抜けるような感じで。"
            </Typography>
          </div>
          <div className="testimonial mt-5">
            <Typography variant="h6"><strong>コーヒーチェーン店員 Bさん</strong></Typography>
            <Typography variant="body2">
              "マンゴーの味がしたんですよ。香りもほんのりフルーティーな感じがあって。本当にマンゴーなのかなというのがあったんですけど、口の中に入れた時にしっかりとした、ジュワッとした少しジューシーさのあるマンゴー感をすごい感じて、そこにエスプレッソならではの香ばしさが乗ってきて、マンゴーのいい甘みの部分がすごい感じられて。2種類飲みました。完熟マンゴーと若さのあるマンゴー。完熟マンゴーは、甘味を重点に置いた完熟マンゴーならではの、ジュワッとした広がりだったんですけど、若さのあるマンゴーはちょっとさっぱりしたシャキッとした甘味、いい意味でさっぱりしていてまとわりつかないような。完熟マンゴーは蜂蜜のような甘味。とろっとした口の中にずっといるような甘味。若い方のマンゴーは粉砂糖のようなさっと甘味が一瞬くるんですけど綺麗にくどくなく消えていく感じがあって、最初の香りに関しては大きな違いはなくて、フルーティーさという共通性を持っていて、口に広がった時に香りの広がり方、あと後味の残り方、同じマンゴーなのに違いが出てきて。"
            </Typography>
          </div>
        </div>
        <Box my={12} />
      </div>
    </div>
  );
};

export default FlavorPage;
