import React from 'react';
import { Box } from '@mui/material';

const CoffeeTicketPurchaseBlock = () => {
  return (
    <Box sx={{
      display: "flex",
      flexDirection: { xs: "column", md: "row" },  // xs（狭い画面）は縦並び、md以上は横並び
      justifyContent: "center",
      alignItems: { xs: "center", md: "flex-start" },  // xs時は中央揃え、md以上は上寄せ
      gap: "30px",
      marginTop: "20px"
    }}>
      {/* 左側：【全力応援プラン】のブロック */}
      <Box sx={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "300px",
        background: "#FFFFFF",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        boxShadow: "-2px 10px 5px rgba(0, 0, 0, 0)",
        borderRadius: "10px",
        fontFamily: "SQ Market, SQ Market, Helvetica, Arial, sans-serif"
      }}>
        <Box sx={{ padding: "20px", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ textAlign: "center", marginBottom: "16px" }}>
            <strong style={{ fontSize: "20px" }}>
              【体験会チケット】<br />40分お手軽コース
            </strong>
            <img 
              src="/ticket_donate.jpeg" 
              alt="ticket_donate" 
              style={{ marginTop: "8px", maxWidth: "100%", borderRadius: "4px" }} 
            />
            <div style={{ fontSize: "16px", marginTop: "4px" }}>
              ①支援者限定サイトへのアクセス権　②岡田と鈴木からの動画メッセージ　③体験会（お手軽）
            </div>
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://square.link/u/9mJcF6dk"
            style={{
              display: "inline-block",
              fontSize: "18px",
              fontWeight: "bold",
              padding: "12px 0",
              color: "#ffffff",
              minWidth: "212px",
              backgroundColor: "#F3874F",
              textAlign: "center",
              boxShadow: "0 0 0 1px rgba(0,0,0,.1) inset",
              borderRadius: "6px",
              whiteSpace: "normal"
            }}>
            体験会チケット（お手軽）<br />
            ¥3,000
          </a>
        </Box>
      </Box>

      {/* 中央：既存の【体験会チケット】ブロック */}
      <Box sx={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "300px",
        background: "#FFFFFF",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        boxShadow: "-2px 10px 5px rgba(0, 0, 0, 0)",
        borderRadius: "10px",
        fontFamily: "SQ Market, SQ Market, Helvetica, Arial, sans-serif"
      }}>
        <Box sx={{ padding: "20px", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ textAlign: "center", marginBottom: "16px" }}>
            <strong style={{ fontSize: "20px" }}>
              【体験会チケット】<br />85分フルコース
            </strong>
            <img 
              src="/ticket_coffee_early.jpeg" 
              alt="ticket_coffee_early" 
              style={{ marginTop: "8px", maxWidth: "100%", borderRadius: "4px" }} 
            />
            <div style={{ fontSize: "16px", marginTop: "4px" }}>
              三田での温度で味が変わるエスプレッソ体験会！3種類の豆を温度を変えながら体験します。<br />
              or<br />
              コーヒー豆配送
            </div>
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://square.link/u/eGpzCbqX"
            style={{
              display: "inline-block",
              fontSize: "18px",
              fontWeight: "bold",
              padding: "12px 0",
              color: "#ffffff",
              minWidth: "212px",
              backgroundColor: "#F3874F",
              textAlign: "center",
              boxShadow: "0 0 0 1px rgba(0,0,0,.1) inset",
              borderRadius: "6px",
              whiteSpace: "normal"
            }}>
            体験会チケット（早割）<br />
            ¥4,500
          </a>
          <div style={{ fontSize: "16px", marginTop: "4px" }}>
            ※チケットご購入後に体験日予約リンクを送付
          </div>
        </Box>
      </Box>

      {/* 右側：追加の【ゲイシャ個別体験チケット】ブロック */}
      <Box sx={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "300px",
        background: "#FFFFFF",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        boxShadow: "-2px 10px 5px rgba(0, 0, 0, 0)",
        borderRadius: "10px",
        fontFamily: "SQ Market, SQ Market, Helvetica, Arial, sans-serif"
      }}>
        <Box sx={{ padding: "20px", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ textAlign: "center", marginBottom: "16px" }}>
            <strong style={{ fontSize: "20px" }}>
            【体験会チケット】<br />ゲイシャ体験チケット<br />
            </strong>
            <img 
              src="/ticket_geisha.jpeg" 
              alt="ticket_geisha" 
              style={{ marginTop: "8px", maxWidth: "100%", borderRadius: "4px" }} 
            />
            <div style={{ fontSize: "16px", marginTop: "4px" }}>
              東京・三田のカフェを貸し切り、ゲイシャ体験会へご招待<br />
              or<br />
              ゲイシャコーヒー豆配送
            </div>
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://square.link/u/yhkbxOuf"
            style={{
              display: "inline-block",
              fontSize: "18px",
              fontWeight: "bold",
              padding: "12px 0",
              color: "#ffffff",
              minWidth: "212px",
              backgroundColor: "#F3874F",
              textAlign: "center",
              boxShadow: "0 0 0 1px rgba(0,0,0,.1) inset",
              borderRadius: "6px",
              whiteSpace: "normal"
            }}>
            【ゲイシャ体験チケット】<br />
            ¥10,000
          </a>
          <div style={{ fontSize: "16px", marginTop: "4px" }}>
            ※チケットご購入後に体験日予約リンクを送付
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default CoffeeTicketPurchaseBlock;