import React, { useEffect } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
// import AuthCheck from './components/AuthCheck';
import CoffeeTicketPurchaseBlock from './components/CoffeeTicketPurchaseBlock';

const EventPage = () => {
  const crowdfundingLink = "https://camp-fire.jp/projects/820485/preview?token=1hual1g5&utm_campaign=cp_po_share_c_msg_projects_show";

  // useEffect(() => {
  //   // Square Appointmentsのスクリプトを動的に読み込む
  //   const script = document.createElement('script');
  //   script.src = 'https://square.site/appointments/buyer/widget/vumnz3f28lhi07/LJ6W66VZWPQ0V.js';
  //   script.async = true;
  //   document.getElementById('square-appointments-container').appendChild(script);
    
  //   // コンポーネントのアンマウント時にスクリプトを削除
  //   return () => {
  //     if (document.getElementById('square-appointments-container').contains(script)) {
  //       document.getElementById('square-appointments-container').removeChild(script);
  //     }
  //   };
  // }, 
  // []); // 空の依存配列で、マウント時のみ実行

  return (
    <Container>
      <Box textAlign="center" my={5}>
        <Typography variant="h3" gutterBottom>
          クラウドファンディング特設サイト
        </Typography>
        <Typography>
          温度を自由に調整できる<strong>特別なマシン</strong>を開発し、コーヒーの風味変化を体験できる<strong>試飲イベント</strong>を開催。
        </Typography>
        <Typography>
          この体験を広めるため、<strong>2025年3月22日（土）よりクラウドファンディング</strong>を実施します。
        </Typography>

        {/* タイトルと説明のブロック（ボタン枠の外） */}
        <div style={{ marginTop: "60px", textAlign: "center" }}>
          <strong style={{ fontSize: "30px", display: "block", marginBottom: "8px" }}>
            体験会チケット先行販売開始！
          </strong>
          <span style={{ fontSize: "16px" }}>
            クラウドファンディング実施前に、体験会チケットを先行販売いたします。
          </span>
        </div>

        {/* 購入リンクブロック */}
        <CoffeeTicketPurchaseBlock />

        {/* タイトルと説明のブロック（ボタン枠の外） */}
        <div style={{ textAlign: "center", marginTop: "30px" }}>
          <strong style={{ fontSize: "30px", display: "block", marginBottom: "15px" }}>
            体験会実施概要
          </strong>
        </div>
        <div style={{
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "left",
          maxWidth: "600px"
        }}>
          <span style={{ fontSize: "16px" }}>
            実施場所：三田駅徒歩7分（慶應義塾大学前）<br/>
            予約方法：チケット購入後、メールアドレスに予約リンクを送付<br/>
            開催時期：3月29日（土）以降の土日で実施（3〜5月）<br/>
            最低開催規定：最低月3日開催（予約多数の場合は増枠）<br/>
            出席不可時：コーヒー豆送付でのご対応となります。
          </span>
        </div>

        {/* クラウドファンディングページへのリンクボタン */}
        <Box display="flex" justifyContent="center" my={3}>
          <Button 
            variant="outlined" 
            color="secondary" 
            href={crowdfundingLink} 
            target="_blank"
            className="rounded-pill"
            size="large"
          >クラウドファンディングページへ
          </Button>
        </Box>

        {/* Square AppointmentsのスクリプトをuseEffectで読み込む */}
        {/* <div id="square-appointments-container"></div> */}

        {/* 画像を横並びで表示 */}
        <div style={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
          <img 
            src="/ticket_howtodrink.png" 
            alt="How to Drink" 
            style={{ margin: "0 10px", maxWidth: "60%", borderRadius: "4px" }} 
          />
          <img 
            src="/ticket_menew.png" 
            alt="Menu" 
            style={{ margin: "0 10px", maxWidth: "60%", borderRadius: "4px" }} 
          />
        </div>

        {/* タイトルと説明のブロック（ボタン枠の外） */}
        <div style={{ marginTop: "90px", textAlign: "center" }}>
          <strong style={{ fontSize: "30px", display: "block", marginBottom: "8px" }}>
          TOKYO ESPRESSO LAB始動！
          </strong>
          <span style={{ fontSize: "16px" }}>
          TOKYO ESPRESSO LABは、AIエスプレッソマシン"Connoisseur"で、誰もが最高の一杯を体験できる世界を目指します。<br/>
          バリスタの技をテクノロジーで再現・共有し、新たなコーヒー文化を創造 2025年4月から東京・三田で常設カフェを開催。<br/>
          皆様と共に未来のコーヒー体験を創出します。
          </span>
        </div>

        {/* クラウドファンディングページへのリンクボタン */}
        {/* <Box display="flex" justifyContent="center" my={3}>
          <Button 
            variant="outlined" 
            color="secondary" 
            href={crowdfundingLink} 
            target="_blank"
            className="rounded-pill"
            size="large"
          >クラファンページを見る
          </Button>
        </Box> */}

        {/* クラウドファンディング画像 */}
        <Box display="flex" justifyContent="center" my={2}>
          <img src="/250224_fanding.jpeg" alt="クラウドファンディング画像" style={{ width: '70%', height: 'auto' }} />
        </Box>
        <Box my={10} />

        <Typography variant="h5" gutterBottom>
          体験で広がる、新しいコーヒーの世界
        </Typography>
        <Box my={3} />
        <Typography>
          コーヒーの味わいは、温度によって驚くほど変化することをご存じですか？
        </Typography>
        <Typography>
          私たちのプロジェクトでは、温度を自由に調整できる特別なマシンを開発しました。
        </Typography>
        
        <Box my={2} />

        <Typography >
          このマシンを使い、さまざまな温度によるコーヒーの風味の変化を体験できる
          <strong>特別な試飲イベント</strong> を開催し、
        </Typography>
        <Typography >
          <strong>この体験をより多くの人に届けるために、クラウドファンディングを実施します。</strong>
        </Typography>

        <Box my={20}>
          <Typography variant="h5" gutterBottom>
            クラウドファンディング概要
          </Typography>
          <Box my={3} />
          <Typography paragraph>
            <strong>プロジェクト:</strong> 【東京】集え！エスプレッソ探求者たち！ESPRESSO LAB TOKYO、始動
          </Typography>
          <img src="/250224_fanding2.jpeg" alt="クラウドファンディング画像" style={{ width: '50%', height: 'auto', marginTop: '10px' }} />
          <Box my={3} />
          <Typography paragraph>
            <strong>期間:</strong>3月29日 〜
          </Typography>
          <Typography paragraph>
            <strong>リターン</strong>
          </Typography>
          <div>
            <p>支援者限定試飲イベントへのご招待</p>
            <p>貸切プランのご案内</p>
            <p>特別なコーヒー豆のギフトセット など</p>
          </div>
        </Box>

        <Box my={10}>
          <Typography variant="h5" gutterBottom>
            体験会について
          </Typography>
          <Box my={3} />
          <Typography paragraph>
            <strong>「温度で変わるコーヒーの味」体験会</strong>
          </Typography>
          <div>
            <p>温度の違いによる味わいの変化をじっくり体感</p>
            <p>プロのコーヒーエキスパートによる解説</p>
            <p>体験者限定の特別なコーヒーセットの販売</p>
          </div>
        </Box>
        
        <Box my={20}>
          <Typography variant="h5" gutterBottom>
            クラウドファンディングページへ
          </Typography>
          <Box my={3} />
          <Typography paragraph>
            皆様のご支援に感謝します。
          </Typography>
          {/* クラウドファンディングページへのリンクボタン */}
          <Box display="flex" justifyContent="center" my={3}>
            <Button 
              variant="outlined" 
              color="secondary" 
              href={crowdfundingLink} 
              target="_blank"
              className="rounded-pill"
              size="large"
            >クラウドファンディングページへ
            </Button>
          </Box>
        </Box>


        {/* タイトルと説明のブロック（ボタン枠の外） */}
        <div style={{ marginTop: "60px", textAlign: "center" }}>
          <strong style={{ fontSize: "30px", display: "block", marginBottom: "8px" }}>
            体験会チケット先行販売中！
          </strong>
          <span style={{ fontSize: "16px" }}>
            クラウドファンディング実施前に、体験会チケットを先行販売いたします。
          </span>
        </div>


        {/* 購入リンクブロック */}
        <CoffeeTicketPurchaseBlock />


      </Box>
    </Container>
  );
};

export default EventPage;
