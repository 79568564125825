import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Chip } from '@mui/material';
import notifications from '../notificationsData';
import { useAuthenticator } from '@aws-amplify/ui-react';

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}.${month}.${day}`;
};

const NewsList = ({ maxItems }) => {
  const { user } = useAuthenticator((context) => [context.user]);

  // 会員限定コンテンツを除外したリストを作成
  const filteredNotifications = notifications.filter(notification => {
    return !notification.requiresAuth || (notification.requiresAuth && user);
  });

  return (
    <div className="recent-news-list">
      {filteredNotifications.slice(0, maxItems).map((notification, index) => {
        const linkTo = notification.redirectLink || `/news/${notifications.indexOf(notification)}`;
        return (
          <Link to={linkTo} className="recent-news-item" key={index}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item className="d-flex align-items-center">
                <Typography variant="body2" color="textSecondary" className="me-5" style={{ fontSize: '80%' }}>
                  {formatDate(notification.date)}
                </Typography>
                <Typography variant="h5" className="mb-1 text-start" style={{ fontSize: '100%' }}>
                  {notification.title}
                </Typography>
              </Grid>
              <Grid item className="tags">
                {notification.tags.map((tag, tagIndex) => (
                  <Chip 
                    key={tagIndex} 
                    label={tag} 
                    className="me-2" 
                    style={{ 
                      backgroundColor: 'rgba(255, 255, 255, 0)',
                      borderColor: 'purple',
                      color: 'purple',
                      borderWidth: '1px',
                      borderStyle: 'solid'
                    }} 
                  />
                ))}
                {notification.requiresAuth && user && (
                  <Chip 
                    label="会員限定" 
                    className="me-2" 
                    style={{ 
                      backgroundColor: 'rgba(255, 255, 255, 0)',
                      borderColor: 'red',
                      color: 'red',
                      borderWidth: '1px',
                      borderStyle: 'solid'
                    }} 
                  />
                )}
              </Grid>
            </Grid>
          </Link>
        );
      })}
    </div>
  );
};

export default NewsList; 