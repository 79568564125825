// connoisseur-site/src/NewPage.js
import React from 'react';

function LinksPage() {
  return (
    <div>
      <h1>リンク集</h1>
      <p>
        <a href="https://knowledge.sakura.ad.jp/37555/" target="_blank" rel="noopener noreferrer">
          さくらのナレッジ：大学生がエスプレッソの味を完全自動化!?Connoisseur 〜新たな体験を切り拓く革新的システムに迫る〜
        </a><br />
        <a href="https://note.com/ipsj/n/nae90bb7cf708" target="_blank" rel="noopener noreferrer">
          情報処理学会：乳化量最大化を目指したエスプレッソ抽出制御システムConnoisseur
        </a><br />
        <a href="https://connoisseur.ltd/news/5" target="_blank" rel="noopener noreferrer">
          未踏会議2024に出展しました！
        </a><br />
        <a href="https://south65.jp/20240315mkmd/" target="_blank" rel="noopener noreferrer">
          世界を変える日本の頭脳が秋葉原に集結！　「未踏会議 2024 MEET DAY」開催
        </a><br />
        <br />

        <a href="https://www.ipa.go.jp/jinzai/mitou/it/2023/gaiyou_oc-2.html" target="_blank" rel="noopener noreferrer">
          採択概要：未踏IT人材発掘・育成事業：2023年度採択プロジェクト概要（岡田PJ）
        </a><br />
        <a href="https://www.youtube.com/watch?v=Ol6Wko-c7e8&ab_channel=IPAChannel" target="_blank" rel="noopener noreferrer">
          成果報告会動画：【2023年度未踏／No.18】乳化量最大化を目指したエスプレッソ抽出制御システムの開発
        </a><br />
        <a href="https://www.ipa.go.jp/jinzai/mitou/it/2023/seika.html#:~:text=%E4%B9%B3%E5%8C%96%E9%87%8F%E6%9C%80%E5%A4%A7%E5%8C%96%E3%82%92%E7%9B%AE%E6%8C%87%E3%81%97%E3%81%9F%E3%82%A8%E3%82%B9%E3%83%97%E3%83%AC%E3%83%83%E3%82%BD%E6%8A%BD%E5%87%BA%E5%88%B6%E5%BE%A1%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0%E3%81%AE%E9%96%8B%E7%99%BA" target="_blank" rel="noopener noreferrer">
          成果報告書：乳化量最大化を目指したエスプレッソ抽出制御システムの開発
        </a><br />
        <a href="https://www.meti.go.jp/press/2024/06/20240603002/20240603002.html#:~:text=%E3%83%AD%E3%83%9C%E3%83%83%E3%83%88%E3%81%AE%E9%96%8B%E7%99%BA-,%E5%B2%A1%E7%94%B0%20%E6%8B%93%E7%9C%9F%EF%BC%88%E3%81%8A%E3%81%8B%E3%81%A0%20%E3%81%9F%E3%81%8F%E3%81%BE%EF%BC%89,-%E6%89%80%E5%B1%9E%EF%BC%9A%E5%AF%8C%E5%A3%AB%E9%80%9A%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BE" target="_blank" rel="noopener noreferrer">
          経済産業省公式HP：2023年度未踏IT人材発掘・育成事業スーパークリエータを認定しました！
        </a><br />



      </p>
    </div>
  );
}

export default LinksPage;