import React from 'react';
import { Container} from '@mui/material';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import './App.css';

Amplify.configure(awsconfig);

function App({ user, signOut }) {
  return (
    <div className="App">
      {user && (
        <Container sx={{ mt: 5, textAlign: 'center', backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '8px' }}>
          <p style={{ fontSize: '18px', fontWeight: 'bold' }}>ログインに成功しました。</p>
          {/* <p style={{ fontSize: '16px', color: '#555' }}>Email: {user.attributes?.email}</p> */}
        </Container>
      )}
    </div>
  );
}

export default withAuthenticator(App);

