// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import TabOverview from './tab-Overview';
import 'bootstrap/dist/css/bootstrap.min.css'; // 追加
import { BrowserRouter } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react'; // 追加

ReactDOM.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <BrowserRouter>
        <TabOverview />
      </BrowserRouter>
    </Authenticator.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
